
import { Vue, Component } from 'vue-property-decorator'
import WrapperAside from './WrapperAside.vue'

@Component({
  components: {
    WrapperAside
  }
})
export default class WrapperConsole extends Vue {

}
