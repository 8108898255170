
import { Vue, Component } from 'vue-property-decorator'
import EntryMobile from './EntryMobile.vue'
import EntryConsole from './EntryConsole.vue'

@Component({
  components: {
    EntryMobile,
    EntryConsole
  }
})
export default class Wrapper extends Vue {

}
